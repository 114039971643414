// =============================
// selectmain
// Ragle Inc.
// =============================

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // Development
  baseUrl: 'https://api.groundworks-dev.ragleinc.com/', // Change this to the address of your backend API if different from frontend address
  apiUrl: 'https://api.groundworks-dev.ragleinc.com/odata/v1.0',  // Change this to the address of your backend API if different from frontend address
  tokenUrl: 'https://api.groundworks-dev.ragleinc.com', // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl 

  // //Production
  // baseUrl: 'https://api.groundworks.ragleinc.com/', // Change this to the address of your backend API if different from frontend address
  // apiUrl: 'https://api.groundworks.ragleinc.com/odata/v1.0',  // Change this to the address of your backend API if different from frontend address
  // tokenUrl: 'https://api.groundworks.ragleinc.com', // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl 


  loginUrl: '/login',
  mapApiKey: 'AIzaSyBYoLSuUOeRU9MbVXn-H54L_3EeAGukwtQ'
};
