import { Menu } from './menu.model';
import { Permission } from '../../../security/shared/models/permission.model';

// public id: number,
// public title: string,
// public routerLink: string,
// public href: string,
// public icon: string,
// public target: string,
// public hasSubMenu: boolean,
// public parentId: number,
// public role: string
export const verticalMenuItems = [
    new Menu(1, 'Timecard', '/mytimecard', null, 'schedule', null, false, 0, [Permission.readTimecardsPermission]),
    new Menu(2, 'Projects', '/projects', null, 'construction', null, false, 0, [Permission.readProjectsPermission]),
    new Menu(3, 'Crew Dailys', '/dailys', null, 'groups', null, false, 0, [Permission.readCrewsPermission]),
    // new Menu(4, 'Timecards', '/dailys', null, 'access_time', null, false, 3, [Permission.readCrewsPermission]),
    // new Menu(5, 'Projections', '/projections', null, 'trending_up', null, false, 3, [Permission.readCrewsPermission]),
    new Menu(6, 'Approvals', '/approvals', null, 'task_alt', null, false, 0, [Permission.approveTimecardsPermission, Permission.approveJhasPermission,
    Permission.approveQuantitiesPermission, Permission.approveDiariesPermission]),
    new Menu(7, 'HR', '', null, 'people', null, true, 0, [Permission.readEmployeesPermission]),
    new Menu(8, 'Timecards', '/timecards', null, 'access_time', null, false, 7, [Permission.readTimecardsPermission]),
    new Menu(9, 'Quantities', '/quantities', null, 'hourglass_empty', null, false, 7, [Permission.readQuantitiesPermission]),
    new Menu(10, 'Employees', '/employees', null, 'people_outline', null, false, 7, [Permission.readEmployeesPermission]),
    new Menu(11, 'Accounting', '', null, 'attach_money', null, true, 0, [Permission.readReceiptsPermission]),
    new Menu(12, 'Expense Approvals', '/receipts/approvals', null, 'task_alt', null, false, 11, [Permission.readReceiptsPermission]),
    new Menu(12, 'Receipts', '/receipts', null, 'receipt', null, false, 11, [Permission.readReceiptsPermission]),
    new Menu(13, 'Transactions', '/creditcardtransactions', null, 'payments', null, false, 11, [Permission.readReceiptsPermission]),
    new Menu(14, 'Credit Cards', '/creditcards', null, 'credit_card', null, false, 11, [Permission.exportReceiptsPermission]),
    new Menu(15, 'Reports', '', null, 'analytics', null, true, 0, [Permission.readExecReportsPermission]),
    new Menu(16, 'Cost Summary Report', '/reports/cost-summary', null, 'monetization_on', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(16, 'Revenue Report', '/reports/revenue-report', null, 'trending_up', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(17, 'Admin', '', null, 'settings', null, true, 0, [Permission.updateUsersPermission, Permission.updateRolesPermission, Permission.updateCrewsPermission]),
    // new Menu(22, 'Incidents', '/incidents', null, 'local_hospital', null, false, 17, [Permission.readCrewsPermission]),
    // new Menu(23, 'Gps Logs', '/gpslogs', null, 'location_on', null, false, 17, [Permission.readCrewsPermission]),
    // new Menu(24, 'Geofences', '/geofences', null, 'map', null, false, 17, [Permission.readCrewsPermission]),
    new Menu(18, 'Crews', '/crews', null, 'groups', null, false, 17, [Permission.readCrewsPermission]),
    new Menu(19, 'Users', '/users', null, 'manage_accounts', null, false, 17, [Permission.readUsersPermission]),
    new Menu(20, 'Roles', '/roles', null, 'security', null, false, 17, [Permission.readRolesPermission]),
    new Menu(21, 'Company', '/company', null, 'domain', null, false, 17, [Permission.readSettingsPermission]),
];

export const horizontalMenuItems = [
    new Menu(1, 'Timecard', '/mytimecard', null, 'schedule', null, false, 0, [Permission.readTimecardsPermission]),
    new Menu(2, 'Projects', '/projects', null, 'construction', null, false, 0, [Permission.readProjectsPermission]),
    new Menu(3, 'Crew Dailys', '/dailys', null, 'groups', null, false, 0, [Permission.readCrewsPermission]),
    // new Menu(4, 'Timecards', '/dailys', null, 'access_time', null, false, 3, [Permission.readCrewsPermission]),
    // new Menu(5, 'Projections', '/projections', null, 'trending_up', null, false, 3, [Permission.readCrewsPermission]),
    new Menu(6, 'Approvals', '/approvals', null, 'task_alt', null, false, 0, [Permission.approveTimecardsPermission, Permission.approveJhasPermission,
    Permission.approveQuantitiesPermission, Permission.approveDiariesPermission]),
    new Menu(7, 'HR', '', null, 'people', null, true, 0, [Permission.readEmployeesPermission]),
    new Menu(8, 'Timecards', '/timecards', null, 'access_time', null, false, 7, [Permission.readTimecardsPermission]),
    new Menu(9, 'Quantities', '/quantities', null, 'hourglass_empty', null, false, 7, [Permission.readQuantitiesPermission]),
    new Menu(10, 'Employees', '/employees', null, 'people_outline', null, false, 7, [Permission.readEmployeesPermission]),
    new Menu(11, 'Accounting', '', null, 'attach_money', null, true, 0, [Permission.readReceiptsPermission]),
    new Menu(12, 'Receipts', '/receipts', null, 'receipt', null, false, 11, [Permission.readReceiptsPermission]),
    new Menu(13, 'Transactions', '/creditcardtransactions', null, 'payments', null, false, 11, [Permission.readReceiptsPermission]),
    new Menu(14, 'Credit Cards', '/creditcards', null, 'credit_card', null, false, 11, [Permission.exportReceiptsPermission]),
    new Menu(15, 'Reports', '', null, 'analytics', null, true, 0, [Permission.readExecReportsPermission]),
    new Menu(16, 'Cost Summary Reports', '/reports/cost-summary', null, '', null, false, 15, [Permission.readExecReportsPermission]),
    new Menu(17, 'Admin', '', null, 'settings', null, true, 0, [Permission.updateUsersPermission, Permission.updateRolesPermission, Permission.updateCrewsPermission]),
    // new Menu(22, 'Incidents', '/incidents', null, 'local_hospital', null, false, 17, [Permission.readCrewsPermission]),
    // new Menu(23, 'Gps Logs', '/gpslogs', null, 'location_on', null, false, 17, [Permission.readCrewsPermission]),
    // new Menu(24, 'Geofences', '/geofences', null, 'map', null, false, 17, [Permission.readCrewsPermission]),
    new Menu(18, 'Crews', '/crews', null, 'groups', null, false, 17, [Permission.readCrewsPermission]),
    new Menu(19, 'Users', '/users', null, 'manage_accounts', null, false, 17, [Permission.readUsersPermission]),
    new Menu(20, 'Roles', '/roles', null, 'security', null, false, 17, [Permission.readRolesPermission]),
    new Menu(21, 'Company', '/company', null, 'domain', null, false, 17, [Permission.readSettingsPermission]),
];



